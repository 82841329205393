import React from "react";
import { useSelector } from "react-redux";
import "../sections/OrderTracking/OrderTracking.scss";

import { landingPageProducts } from "../utils/data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bannerLarge from "./../assets/images/pigeonLandingPage/Her_banner.webp";
import bannerSmall from "./../assets/images/pigeonLandingPage/Responsive Banner 02.jpg";
import bannerLargeAr from "./../assets/images/pigeonLandingPage/Her_banner Ar.jpg";
import bannerSmallAr from "./../assets/images/pigeonLandingPage/Responsive Banner AR.jpg";
import fifth_section__img from "./../assets/images/pigeonLandingPage/fifth_section__img.png";
import fifth_section_ar_img from "./../assets/images/pigeonLandingPage/fifth_section_ar_img.png";
import sixth_section_img from "./../assets/images/pigeonLandingPage/sixth_section_img.webp";
import anti_colic_system from "./../assets/images/pigeonLandingPage/ANTI-COLIC SYSTEM.webp";
import c1 from "./../assets/images/pigeonLandingPage/c1.png";
import c2 from "./../assets/images/pigeonLandingPage/c2.png";
import c3 from "./../assets/images/pigeonLandingPage/c3.png";
import element_03 from "./../assets/images/pigeonLandingPage/element_03.jpg";
import element_04 from "./../assets/images/pigeonLandingPage/element_04.png";
import element_04_ar from "./../assets/images/pigeonLandingPage/element_04_ar.png";
import latch_on_line from "./../assets/images/pigeonLandingPage/LATCH-ON LINE.webp";
import soft_and_flexible_nipple from "./../assets/images/pigeonLandingPage/SOFT & FLEXIBLE NIPPLE.webp";

import { constantsAr, constantsEn } from "../utils/landingPageConstants";
import IframeComponent from "../components/Landing/IframeComponent";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const Landing = () => {
  const global = useSelector((state) => state.globalReducer);
  const constants = global.activeLanguage === "en" ? constantsEn : constantsAr;

  const productEnquiry = (product) => {
    window.localStorage.setItem("size", product.size);
    window.localStorage.setItem("color", product.color);
    if (product.url) {
      if (typeof window !== "undefined") {
        if (window.dataLayer != null) {
          // window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "view_item",
            ecommerce: {
              currency: "AED",
              value: product?.price,
              items: [
                {
                  id: product?.id,
                  name: product.name,
                  sku: product?.sku,
                  price: product?.price,
                  brand: "Pigeon Arabia",
                  category: 'bottlefeeding',
                  size: product?.size,
                  variant: product?.color
                },
              ],
            },
          });
        }
      }
      window.open(product.url,"_self");
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="landing-main-class">
      <section className="__desktop-banner">
        <img
          src={global.activeLanguage === "en" ? bannerLarge : bannerLargeAr}
          alt="Banner"
          className="__negative-margin-top"
        />
      </section>
      <section className="__mobile-banner">
        <img
          src={global.activeLanguage === "en" ? bannerSmall : bannerSmallAr}
          alt="Banner"
        />
      </section>

      <section className="__section-padding-small __z-index-30">
        <div className="__text-center __max-width-4xl __padding-4 __z-index-30">
          <h1 className="__text-primary __text-2xl __font-bold __margin-bottom-4 __z-index-30">
            {constants?.dearMoms}
          </h1>
          <p className="__text-gray __text-base __font-thin __text-sm __margin-bottom-4 __z-index-30">
            {constants?.motherhoodMessage}
          </p>
          <p className="__text-gray __text-base __font-thin __text-sm __z-index-30">
            {constants?.introducingBottle}
          </p>
        </div>
      </section>

      <section className="__landing-bg-primary __landing-py-8">
        <h1 className="__landing-text-heading __landing-mb-4 __landing-text-center">
          {constants?.naturalSwitch}
        </h1>
        <div className="__landing-container">
          <div className="__landing-grid">
            <div className="__landing-card">
              <div className="__landing-img-wrapper">
                <img
                  src={latch_on_line}
                  alt="Image of a baby bottle with latch-on line"
                  className="__landing-img"
                />
              </div>
              <p className="__landing-text">{constants?.latchDesc}</p>
              <div className="__landing-card-footer">
                <div className="__landing-card-footer-bg">
                  <span className="__landing-card-footer-text">
                    {constants?.latch}
                  </span>
                </div>
              </div>
            </div>
            <div className="__landing-card">
              <div className="__landing-img-wrapper">
                <img
                  src={soft_and_flexible_nipple}
                  alt="Image of a baby bottle with soft and flexible nipple"
                  className="__landing-img"
                />
              </div>
              <p className="__landing-text">{constants?.softestDesc}</p>
              <div className="__landing-card-footer">
                <div className="__landing-card-footer-bg">
                  <span className="__landing-card-footer-text">
                    {constants?.softFlexibleNipple}
                  </span>
                </div>
              </div>
            </div>
            <div className="__landing-card">
              <div className="__landing-img-wrapper">
                <img
                  src={anti_colic_system}
                  alt="Image of a baby bottle with anti-colic system"
                  className="__landing-img"
                />
              </div>
              <p className="__landing-text">
                {constants?.antiColicSystemDescription}
              </p>
              <div className="__landing-card-footer">
                <div className="__landing-card-footer-bg">
                  <span className="__landing-card-footer-text">
                    {constants?.antiColicSystem}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="product-grid">
          <div className="product-header">
            <h1 className="product-header-title">
              <span className="product-header-subtitle">
                {constants.explore}
                <br className="product-header-break" />
              </span>{" "}
              {constants.exploreFullRange}
            </h1>
          </div>
          <div className="product-slider-container">
            <div className="productsSlider slider-container product-slider">
              <Slider {...settings}>
                {landingPageProducts.map((product) => (
                  <div key={product.id} className="product-slide">
                    <div className="product-card">
                      <span className="product-size">{product.size}</span>
                      <img
                        src={product.image}
                        alt={product.alt}
                        className="product-image"
                      />
                      <h3 className="product-name">
                        {global.activeLanguage === "en"
                          ? product.name
                          : product.nameAr}
                      </h3>
                      <div className="product-button-container">
                        <button
                          className="product-button"
                          onClick={() =>
                            productEnquiry(
                              product
                            )
                          }
                        >
                          {constants?.buyNow}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="__support-section">
        <div className="__support-container">
          <h2 className="__support-heading">
            {constants?.supportBreastfeeding}
          </h2>
          <div className="__support-hidden-sm">
            <img
              src={global.activeLanguage == "en" ? element_04 : element_04_ar}
              alt="Created to Support Breastfeeding"
            />
          </div>
          <div className="__support-hidden-lg">
            <img
              src={
                global.activeLanguage == "en"
                  ? fifth_section__img
                  : fifth_section_ar_img
              }
              alt="Created to Support Breastfeeding"
            />
          </div>
        </div>
      </section>

      <section className="__baby-section">
        <div className="__baby-hidden-sm">
          <img src={element_03} alt="element_03" />
        </div>
        <div className="__baby-hidden-lg">
          <img src={sixth_section_img} alt="element_03" />
        </div>
        <div className="__baby-absolute __baby-bottom-40 sm:__baby-bottom-10 __baby-opacity-95">
          <div className="__baby-content">
            <p className="__baby-text">
              {constants?.description1} <br className="__baby-hidden-sm" />
              {constants?.description2}
            </p>
          </div>
        </div>
      </section>

      <section className="__research-section">
        <div className="__research-bg"></div>
        <h1 className="__research-heading">{constants?.researchTitle}</h1>
        <div className="__research-cards-container">
          <div className="__research-card">
            <div className="__research-card-img-wrapper">
              <img src={c1} alt="Latching On" />
            </div>
            <div className="__research-card-content-wrapper">
              <div className="__research-card-content">
                <h2 className="__research-card-title">
                  {constants?.latchingOn}
                </h2>
                <p className="__research-card-text">
                  {constants?.latchingOnDescription}
                </p>
              </div>
            </div>
          </div>
          <div className="__research-card">
            <div className="__research-card-img-wrapper">
              <img src={c2} alt="Sucking" />
            </div>
            <div className="__research-card-content-wrapper">
              <div className="__research-card-content">
                <h2 className="__research-card-title">{constants?.sucking}</h2>
                <p className="__research-card-text">
                  {constants?.suckingDescription}
                </p>
              </div>
            </div>
          </div>
          <div className="__research-card">
            <div className="__research-card-img-wrapper">
              <img src={c3} alt="Swallowing" />
            </div>
            <div className="__research-card-content-wrapper">
              <div className="__research-card-content">
                <h2 className="__research-card-title">
                  {constants?.swallowing}
                </h2>
                <p className="__research-card-text">
                  {constants?.swallowingDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="__study-section">
        <div className="__study-container">
          <div className="__study-video-wrapper">
            <div className="__study-iframe">
                <IframeComponent
                  src="https://www.youtube.com/embed/DvitAj8aTd0?si=WkW9U_EKQPrcOYg4"
                  title="Pigeon SofTouch™ - New and improved"
                />
            </div>
          </div>
          <div className="__study-text-wrapper">
            <h2 className="__study-heading">{constants?.studyTitle}</h2>
            <p className="__study-text">{constants?.studyDescription}</p>
          </div>
        </div>
      </section>

      <section className="__learn-container">
        <h1 className="__learn-heading">{constants?.learnMore}</h1>
        <div className="__learn-grid">
            <IframeComponent
              src="https://www.youtube.com/embed/1oPr2PkVVms?si=VU1L5SO9U4bSVbPQ"
              title="Pigeon SofTouch™ - New and improved"
            />
            <IframeComponent
              src="https://www.youtube.com/embed/_a8lJko9emA?si=3aJ13aPiOzOIZWB4"
              title="Pigeon SofTouch™ - Bringing smiles to every family"
            />
        </div>
      </section>
    </div>
  );
};

export default Landing;
